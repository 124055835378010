<template>
  <el-row>
    <el-col>
      <div class="tee-time-header" style="display: flex">
        <div style="width: 100px; float: left">Start</div>
        <div style="width: 50px; float: left; text-align: left">Hl</div>
        <div
          class="hide-on-md"
          style="min-width: 50px; float: left; text-align: left"
        >
          Op
        </div>
        <div
          class="hide-on-md"
          style="min-width: 120px; width: 10%; float: left"
        >
          Baan
        </div>
        <div style="flex-grow: 1">Spelers</div>
        <div style="min-width: 50px; width: 10%; float: left">HCP</div>
      </div>
      <div style="max-height: calc(100vh - 160px); overflow-y: scroll">
        <tee-time-line
          v-for="(teeTime, key) in teeTimeArray"
          :key="key"
          :teeTime="teeTime"
        ></tee-time-line>
      </div>
    </el-col>
  </el-row>
</template>

<script>
export default {
  components: {
    TeeTimeLine: require("./TeeTimeLine.vue").default,
  },
  props: {
    teeTimes: Array,
    letter: String,
  },
  computed: {
    teeTimeArray() {
      const now = this.timeToMinutes(this.$dayjs().format("HH:mm:ss")) - 240;

      if (this.letter.length > 0) {
        return this.teeTimes.filter((time) => {
          const intTime = this.timeToMinutes(time.timeFrom);
          return time.crlChar === this.letter && now < intTime;
        });
      }

      return this.teeTimes.filter((time) => {
        const intTime = this.timeToMinutes(time.timeFrom);
        return now < intTime;
      });
    },
  },
  methods: {
    timeToMinutes(time) {
      const minutes = parseInt(
        this.$dayjs("1900-01-01" + time, "HH:mm:ss").format("mm")
      );
      const hours = parseInt(
        this.$dayjs("1900-01-01" + time, "HH:mm:ss").format("HH")
      );
      return hours * 60 + minutes;
    },
  },
};
</script>

<style lang="scss">
.tee-time-header {
  overflow-x: scroll;
  font-weight: bold;
}

@media (max-width: 992px) {
  .hide-on-md {
    display: none;
  }
}
</style>
